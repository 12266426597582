<template>
  <b-card>
    <b-media no-body>
      <b-media-aside>
        <b-link>
          <b-avatar
            rounded
            ref="refPreviewLogo"
            v-if="form.avatarUrl"
            :src="form.avatarUrl"
            size="5rem"
          />

          <b-avatar
            rounded
            v-else
            :text="form.username.charAt(0)"
            size="5rem"
          />
        </b-link>
        <!--/ avatar -->
      </b-media-aside>

      <b-media-body class="mt-75 ml-75">
        <!-- upload button -->
        <b-button
          variant="primary"
          size="sm"
          class="mb-75 mr-75"
          @click="$refs.refLogo.$el.click()"
        >
          Upload
        </b-button>
        <!--/ upload button -->

        <!-- reset -->
        <b-button
          variant="outline-secondary"
          size="sm"
          class="mb-75 mr-75"
          @click="removeUploaded"
        >
          Reset
        </b-button>
        <!--/ reset -->
        <b-card-text>Allowed JPG, GIF or PNG. Max size of 800kB</b-card-text>
      </b-media-body>
    </b-media>

    <!-- form -->
    <div class="mt-2">
      <validation-observer ref="formRef">
        <b-form @submit.prevent>

          <b-form-file
            ref="refLogo"
            v-model="form.avatar"
            accept=".jpg, .png, .gif"
            :hidden="true"
            plain
            @input="handleUpload"
          />


          <b-row>
            <!-- user name -->
            <b-col md="8">
              <b-form-group
                :label="$t('apps.profile.singular.userName')"
                label-for="username"
              >
                <b-form-input
                  size="sm"
                  v-model="form.username"
                  name="username"
                />
              </b-form-group>
            </b-col>
            <!--/ company name -->

          </b-row>

          <b-row>
            <!-- email -->
            <b-col md="8">
              <b-form-group
                :label="$t('apps.profile.singular.email')"
                label-for="email"
              >
                <b-form-input
                  id="email"
                  size="sm"
                  v-model="form.email"
                  name="email"
                  disabled
                />
              </b-form-group>
            </b-col>
            <!--/ email -->

          </b-row>

          <b-row>
            <!-- user name -->
            <b-col md="8">
              <b-form-group
                :label="$t('apps.profile.singular.company')"
                label-for="company"
              >
                <b-form-input
                  id="company"
                  size="sm"
                  v-model="form.company"
                  name="company"
                  disabled
                />
              </b-form-group>
            </b-col>
            <!--/ company name -->

          </b-row>

          <b-row>
            <b-col
              cols="12"
              class="d-flex align-items-center justify-content-end mb-1 mb-md-0">

              <b-button
                variant="primary"
                type="submit"
                @click="handleSubmit"
              >
                <feather-icon
                  icon="SaveIcon"
                />
                {{ $t('globalActions.update') }}
              </b-button>
            </b-col>
          </b-row>

        </b-form>
      </validation-observer>
    </div>
  </b-card>
</template>

<script>
import {
  BForm,
  BFormGroup,
  BFormInput,
  BFormFile,
  BRow,
  BCol,
  BCard,
  BButton,
  // BAlert,
  BMedia,
  BMediaAside,
  BMediaBody,
  BLink,
  BAvatar,
  BCardText
} from 'bootstrap-vue'
import { ref } from '@vue/composition-api'
import { ValidationObserver } from 'vee-validate'
//import vSelect from 'vue-select'
import useHttp from '@/comp-functions/useHttp'
import store from '@/store/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BForm,
    BFormGroup,
    BFormInput,
    BFormFile,
    BRow,
    BCol,
    BCard,
    BButton,
    // BAlert,
    BMedia,
    BMediaAside,
    BMediaBody,
    BLink,
    BAvatar,
    BCardText,
    ValidationObserver
    //vSelect
  },
  setup () {
    const user = store.state.user

    const form = ref({
      username: user.name,
      email: user.email,
      company: user.currentCompany.name,
      avatarUrl: user.avatar,
      avatar: null
    })

    const { toast, $put } = useHttp()

    return {
      form,
      $put,
      toast
    }
  },
  methods: {
    handleUpload () {
      const reader = new FileReader()
      if (this.form.avatar) {
        reader.readAsDataURL(this.form.avatar)
        reader.onload = () => {
          this.form.avatarUrl = reader.result
        }
      }
    },
    removeUploaded () {
      this.form.avatar = null
      this.form.avatarUrl = null
    },
    handleSubmit () {
      const $swal = this.$swal

      $swal({
        title: `${this.$t('confirm.updateThis')} ${this.$t('apps.profile.singular.profileUser')} ?`,
        icon: 'question',
        showCancelButton: true,
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-flat-warning'
        }
      }).then(async () => {
        const form = new FormData()
        form.append('avatar', this.form.avatar)
        form.append('user_name', this.form.username)

        const callbackSuccess = (res) => {
          this.$store.commit('user/SET_NAME', res.data.user_name)
          this.$store.commit('user/SET_AVATAR', res.data.avatar)

          this.toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Success',
              icon: 'BellIcon',
              variant: 'success',
              text: `${this.$t('feedback.success')} ${this.$t('feedback.updated')} ${this.$t('apps.profile.singular.profileUser')}.`
            }
          })
        }

        this.$put({
          data: form,
          url: `users/${this.$store.state.user.id}`
        }).then((res) => callbackSuccess(res))
      })
    }
  }
}
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
</style>
